import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Swiper from "swiper/bundle";
import "swiper/css/bundle";

import Choices from "choices.js";

import "choices.js/public/assets/styles/choices.min.css";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// equal height column
function equalizeColumns() {
  if (window.innerWidth >= 992) {
    let currentElement = document.querySelector(".dynamic-column");
    let nextElement = currentElement ? currentElement.nextElementSibling : null;

    if (currentElement && nextElement) {
      nextElement.style.height = currentElement.offsetHeight + "px";
      nextElement.style.width =
        window.innerWidth - currentElement.offsetWidth + "px";
    }
  }
}

equalizeColumns();

window.addEventListener("resize", equalizeColumns);

// light and dark mode logo animation gif
document.addEventListener("DOMContentLoaded", function () {
  if (window.innerWidth > 992) {
    var gifLogo = document.getElementById("gifImage");
    var gifLogoDark = document.getElementById("gifImageDark");
    var staticLogo = document.getElementById("staticImage");
    var staticLogoDark = document.getElementById("staticImageDark");

    if (staticLogo && staticLogoDark) {
      staticLogo.classList.add("hidden");
      staticLogoDark.classList.add("hidden");
    }

    setTimeout(function () {
      if (gifLogo) gifLogo.classList.add("hidden");
      if (gifLogoDark) gifLogoDark.classList.add("hidden");
      if (staticLogo) staticLogo.classList.add("show");
      if (staticLogoDark) staticLogoDark.classList.add("show");
    }, 3500);
  }
});

// audio and swiper
document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll(".o-series-slider").forEach(function (container) {
    var swiperElement = container.querySelector(".swiper");
    var nextButton = container.querySelector(".swiper-button-next");
    var prevButton = container.querySelector(".swiper-button-prev");

    if (swiperElement && nextButton && prevButton) {
      var swiper = new Swiper(swiperElement, {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      });
    }
  });

  const audioIcons = document.querySelectorAll(".icon-audio");

  if (audioIcons.length > 0) {
    audioIcons.forEach((audioIcon) => {
      let audioSrc = audioIcon.getAttribute("data-audio");
      let audio = new Audio(audioSrc);
      let isPlaying = false;

      audioIcon.addEventListener("click", function () {
        if (!isPlaying) {
          audio.play();
          audioIcon.classList.add("active");
          isPlaying = true;
        } else {
          audio.pause();
          audioIcon.classList.remove("active");
          isPlaying = false;
        }

        audio.addEventListener("ended", function () {
          audioIcon.classList.remove("active");
          isPlaying = false;
        });
      });
    });
  }
});

var searchElement = document.getElementById("search-js-choice");
if (searchElement) {
  var search = new Choices(searchElement, {
    allowHTML: true,
    delimiter: ",",
    editItems: true,
    placeholderValue: "click to search",
    removeItemButton: true,
  });
}

setTimeout(function () {
  var parentDiv = document.querySelector(".language-dropdown-container");
  if (parentDiv) {
    parentDiv.classList.add("show");
    var languageElement = parentDiv.querySelector(
      '[name="language-js-choice"]'
    );
    if (languageElement) {
      var language = new Choices(languageElement, {
        allowHTML: true,
        delimiter: ",",
        editItems: true,
        removeItemButton: false,
        searchEnabled: false,
      });
    }
  }
}, 1000);

// Filters on Video and Audio layout - changing from tags on desktop to select dropdown on mobile
window.addEventListener("resize", function () {
  var languageContainers = document.querySelectorAll(
    ".o-video-layout__headline-and-filter"
  );
  languageContainers.forEach(function (container) {
    if (window.innerWidth <= 991) {
      showLanguageSelect(container);
    } else {
      showLanguageTags(container);
    }
  });
});

function showLanguageSelect(container) {
  var languageTagsContainer = container.querySelector(".m-tags__tags-wrapper");
  if (!languageTagsContainer) {
    return; // Exit the function if container doesn't exist
  }

  languageTagsContainer.style.display = "none";

  var languageSelectContainer = container.querySelector(
    ".m-tags__select-wrapper"
  );
  if (languageSelectContainer) {
    var existingSelect = languageSelectContainer.querySelector("select");
    if (!existingSelect) {
      var select = document.createElement("select");
      select.className = "m-tags__child";

      var languageButtons = container.querySelectorAll(
        ".m-tags__tags-wrapper .m-tags__child"
      );
      languageButtons.forEach(function (button) {
        var option = document.createElement("option");
        option.value = button.textContent.toLowerCase();
        option.text = button.textContent;
        select.appendChild(option);
      });
      languageSelectContainer.appendChild(select);
    }
  }
}

function showLanguageTags(container) {
  var languageTagsContainer = container.querySelector(".m-tags__tags-wrapper");
  if (languageTagsContainer) {
    languageTagsContainer.style.display = "flex";
  }

  var languageSelectContainer = container.querySelector(
    ".m-tags__select-wrapper"
  );
  if (languageSelectContainer) {
    languageSelectContainer.innerHTML = ""; // Clear dropdown select
  }
}

// Initial check on page load
var languageContainers = document.querySelectorAll(
  ".o-video-layout__headline-and-filter"
);
languageContainers.forEach(function (container) {
  if (window.innerWidth <= 991) {
    showLanguageSelect(container);
  } else {
    showLanguageTags(container);
  }
});

// SCRIPTS FROM FOOTER
document.querySelectorAll("[data-navigation]").forEach((el) => {
  if (window.location.href.includes("/category/" + el.dataset.navigation)) {
    el.classList.add("is-active");
  }
});

// Add class is-active for Video / Audio filters
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".m-tags__child").forEach((el) => {
    const urlParts = window.location.href.split("/");
    const categoryIndex = urlParts.indexOf("category");

    if (categoryIndex !== -1) {
      if (urlParts[categoryIndex + 2] === el.dataset.category) {
        el.classList.add("is-active");
      }
    }
  });
});

document.addEventListener("DOMContentLoaded", function () {
  function set_dark_mode_cookie() {
    document.cookie = "yonkovNightMode=1; path=/";
  }

  function remove_dark_mode_cookie() {
    document.cookie =
      "yonkovNightMode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  function toggle_dark_mode() {
    const html = document.documentElement;
    const currentTheme = html.getAttribute("data-bs-theme");
    const darkModeImages = document.querySelectorAll(".darkModeImage");
    const lightModeImages = document.querySelectorAll(".lightModeImage");
    const newTheme = currentTheme === "dark" ? "light" : "dark";
    html.setAttribute("data-bs-theme", newTheme);

    if (newTheme === "dark") {
      set_dark_mode_cookie();
      darkModeImages.forEach(function (element) {
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      });
      lightModeImages.forEach(function (element) {
        element.classList.remove("d-flex");
        element.classList.add("d-none");
      });
    } else {
      remove_dark_mode_cookie();
      lightModeImages.forEach(function (element) {
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      });
      darkModeImages.forEach(function (element) {
        element.classList.remove("d-flex");
        element.classList.add("d-none");
      });
    }

    // Update all text-switch elements
    document
      .querySelectorAll(".text-switch")
      .forEach(function (textSwitchSpan) {
        if (newTheme === "dark") {
          textSwitchSpan.textContent = "Switch to light mode";
        } else {
          textSwitchSpan.textContent = "Switch to dark mode";
        }
      });
  }

  function update_dark_mode() {
    var yonkov_night_mode = document.cookie.replace(
      /(?:(?:^|.*;\s*)yonkovNightMode\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (yonkov_night_mode === "1") {
      document.querySelectorAll(".dark-theme-js").forEach(function (btn) {
        btn.classList.add("active");
      });
      document
        .querySelectorAll(".text-switch")
        .forEach(function (textSwitchSpan) {
          textSwitchSpan.textContent = "Switch to light mode";
        });
      document.documentElement.setAttribute("data-bs-theme", "dark");
      toggleImageVisibility(true);
    } else {
      document.querySelectorAll(".dark-theme-js").forEach(function (btn) {
        btn.classList.remove("active");
      });
      document
        .querySelectorAll(".text-switch")
        .forEach(function (textSwitchSpan) {
          textSwitchSpan.textContent = "Switch to dark mode";
        });
      document.documentElement.removeAttribute("data-bs-theme");
      toggleImageVisibility(false);
    }
  }

  function toggleImageVisibility(darkMode) {
    var darkModeImages = document.querySelectorAll(".darkModeImage");
    var lightModeImages = document.querySelectorAll(".lightModeImage");

    if (darkMode) {
      darkModeImages.forEach(function (element) {
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      });
      lightModeImages.forEach(function (element) {
        element.classList.remove("d-flex");
        element.classList.add("d-none");
      });
    } else {
      lightModeImages.forEach(function (element) {
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      });
      darkModeImages.forEach(function (element) {
        element.classList.remove("d-flex");
        element.classList.add("d-none");
      });
    }
  }

  document.querySelectorAll(".dark-theme-js").forEach(function (btn) {
    btn.addEventListener("click", function () {
      toggle_dark_mode();
    });
  });

  update_dark_mode();

  document.querySelectorAll('[name="language-js-choice"]').forEach((el) => {
    el.addEventListener("change", function (e) {
      window.location.href = el.value;
    });
  });
});

var swiperImages = new Swiper(".swiper-images", {
  loop: true,
  slidesPerView: 1,

  navigation: {
    nextEl: ".next-images",
    prevEl: ".prev-images",
  },
});

document.addEventListener("DOMContentLoaded", function () {
  const bigSlider = document.querySelector("#big-slider ul");
  const thumbSlider = document.querySelector("#thumb-slider ul");
  const thumbSlides = document.querySelectorAll("#thumb-slider li");
  const slideCount = thumbSlides.length;
  const visibleThumbs = 4;
  let currentIndex = 0;

  if (bigSlider && thumbSlider && thumbSlides.length > 0) {
    // Select all the gallery links within the big-slider container
    const bigSliderLinks = document.querySelectorAll("#big-slider a.swipebox");

    bigSliderLinks.forEach((link) => {
      // Get the corresponding image element
      const img = link.querySelector('img');

      if (img) {
        // Set the href attribute to the src of the image
        link.setAttribute("href", img.getAttribute("src"));
      }

      // Set the data-fancybox attribute for Fancybox grouping
      link.setAttribute("data-fancybox", "gallery");

      // Get the photographer and title attributes
      const photographer = link.getAttribute("data-fotographer") || "";
      const title = link.getAttribute("title") || "";

      // Create the caption using the title and photographer information
      const caption = `<div class="fancybox-title">${title}</div><div class="fancybox-description">${photographer}</div>`;

      // Set the data-caption attribute with the constructed caption
      link.setAttribute("data-caption", caption);
    });

    function updateSlider() {
      bigSlider.style.transform = `translateX(-${currentIndex * 100}%)`;

      thumbSlides.forEach((thumb, index) => {
        thumb.classList.toggle("active", index === currentIndex);
      });

      const thumbWidth = thumbSlides[0].offsetWidth + 10;
      const maxOffset = Math.max(
          0,
          thumbSlider.scrollWidth - thumbSlider.offsetWidth
      );
      const offset = Math.min(
          (currentIndex - Math.floor(visibleThumbs / 2)) * thumbWidth,
          maxOffset
      );

      thumbSlider.style.transform = `translateX(-${offset}px)`;

      const nextButton = document.querySelector(".next-button");
      if (nextButton) {
        nextButton.disabled = currentIndex >= slideCount - 1;
      }

      const prevButton = document.querySelector(".prev-button");
      if (prevButton) {
        prevButton.disabled = currentIndex <= 0;
      }
    }

    function showNextSlide() {
      if (currentIndex < slideCount - 1) {
        currentIndex++;
        updateSlider();
      }
    }

    function showPreviousSlide() {
      if (currentIndex > 0) {
        currentIndex--;
        updateSlider();
      }
    }

    // Thumbnail click
    thumbSlides.forEach((thumb, index) => {
      thumb.addEventListener("click", function (event) {
        event.preventDefault(); // Prevent default action
        currentIndex = index;
        updateSlider();
      });
    });

    // Navigation buttons
    const nextButton = document.querySelector(".next-button");
    const prevButton = document.querySelector(".prev-button");

    if (nextButton) {
      nextButton.addEventListener("click", showNextSlide);
    }

    if (prevButton) {
      prevButton.addEventListener("click", showPreviousSlide);
    }

    updateSlider();

    // Initialize Fancybox for the gallery links
    Fancybox.bind("#big-slider a.swipebox", {
      captions: function (fancybox, slide) {
        // Use data-caption for the Fancybox caption with custom classes
        return slide.$trigger.getAttribute("data-caption") || "";
      },
    });
  }
});
